import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import partnersHeading from "../images/partnersHeading.svg"
import whyUsLogo from "../images/whyUsLogo.svg"
import partnerstodaylogo from "../images/partnerstodaylogo.svg"
const Partners = () => {
    return (
        <Layout currentPage={'Partners'}>
            <Seo title="Partners" description="Partners Programs" />
            <div>

            <section className="partners-heading">
                    <div className="partners-heading-main">
                        <article>
                            <h6>Partner program</h6>
                            <p>Be part of our history and help us achieve key milestones.</p>
                            {/*<button>Become a Partner !</button>*/}
                        </article>
                        <article>
                            <img src={partnersHeading}/>
                        </article>
                    </div>
                </section>
            
            <section className="whyUs">
                <div className="whyUs-main">
                    <article>
                        <h6>Why</h6>
                        <h3>Partner with us?</h3>
                        <p>We offer competitive agreements to help you create added value to your products and added revenue stream to your business</p>
                        <div className="article-half">
                            <article className="article-first">
                                <h4>| Reseller</h4>
                                <p>Our valued resellers are enjoying strong returning revenue stream with healthy margins and our full support including marketing and training</p>
                            </article>
                            <article className="article-first">
                                <h4>| White-labeling</h4>
                                <p>Our partners build software that leverage blockchain technology, enable new types of products, and innovate business models. We’ve designed a program to help them accelerate and amplify their impact. Become one of them.</p>
                            </article>
                        </div>
                    </article>
                    <article>
                        <img src={whyUsLogo}/>
                    </article>
                </div>
            </section>
            
            <section className="partners-today">
                <div className="partners-today-main">
                    <article>
                        <img src={partnerstodaylogo}/>
                    </article>
                    <article>
                        <h6>Become a</h6>
                        <h3>Partner Today!</h3>
                        <p>By entering into a partnership with us, you get access to the informationthat will help you build reliable & scalable integrations, launch and scale new amazing products, help new customersuse our awasome digital asset certification solution, get regular partner updates, access best practiceadvices and training access.</p>
                        <p>Let’s join forces today for a better tomorrow. Contact us and lets talk how we can help eachother.</p>
                    </article>
                </div>
            </section>
            </div>
        </Layout>
    )
}
export default Partners